@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: relative;
  max-width: 430px;
  display: flex;
  justify-content: center;
}

.dices {
  width: 85%;
}

.stars {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.star1 {
  width: 16px;
  height: 16px;
  position: absolute;
  animation: glow 3s infinite linear;
  bottom: 30px;
  left: 60px;
  transform: rotate(-5deg);
}

.star2 {
  width: 18px;
  height: 18px;
  position: absolute;
  animation: glow 3s infinite linear;
  bottom: 180px;
  left: 60px;
  transform: rotate(-10deg);
}

.star3 {
  width: 15px;
  height: 15px;
  position: absolute;
  animation: glow 3s infinite linear;
  bottom: 50px;
  left: 15px;
  transform: rotate(-14deg);
}

.star4 {
  width: 13px;
  height: 13px;
  position: absolute;
  animation: glow 3s infinite linear;
  bottom: 160px;
  left: 105px;
  transform: rotate(12deg);
}

.star5 {
  width: 15px;
  height: 15px;
  position: absolute;
  animation: glow 3s infinite linear;
  bottom: 160px;
  right: 80px;
  transform: rotate(10deg);
}

.star6 {
  width: 12px;
  height: 12px;
  position: absolute;
  animation: glow 3s infinite linear;
  bottom: 120px;
  right: 30px;
  transform: rotate(20deg);
}

.star7 {
  width: 13px;
  height: 13px;
  position: absolute;
  animation: glow 3s infinite linear;
  bottom: 170px;
  right: 60px;
  transform: rotate(-10deg);
}

.darker {
  opacity: 0.5;
}

@keyframes glow {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
