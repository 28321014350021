@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  border: 1.6px solid transparent;
  background: linear-gradient(6deg, transparent, #ffecca, transparent)
    border-box;
  border-radius: 16px;
}

.container {
  display: flex;
  padding: 5px 14px;
  background: linear-gradient(240.15deg, #f9dca0 -13.18%, #fe7762 111.68%);
  border-radius: 30px;
}

.text {
  font-weight: 600;
  font-size: 16px;
  color: #702800;
  text-align: center;
}
