@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  min-width: 62px;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
}

.getMore {
  width: 58px;
  height: 58px;

  &Text {
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    @include text-purple;
  }
}

.boostText {
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  @include text-gold;
}

.range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: linear-gradient(
      to right,
      transparent var(--value, 0%),
      #0e0e0e var(--value, 0%)
    ),
    linear-gradient(250.37deg, #f9dca0 13.15%, #fe7762 86.85%);
  border: 1.6px solid $white;
  border-radius: 20px;
  outline: none;
  transition: all 0.3s;
  opacity: 1;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}

.values {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.value {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  @include text-orange;
}

.rewards {
  display: flex;
  justify-content: space-between;
}

.rewardWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 60px;
  height: 50px;
}

.icon {
  opacity: 0.5;
  @include glow(6px, #f5c35f99);
  width: 25px;
  height: 25px;

  &Big {
    width: 60px;
    height: 60px;
    @include glow(6px, #f5c35f99);
    position: absolute;
    top: -6px;
    left: 0;
  }

  &Dice {
    opacity: 0.5;
    @include glow(6px, #f5c35f99);
    position: absolute;
    top: -5px;
    left: 3px;
  }

  &Coin {
    opacity: 0.5;
    @include glow(6px, #f5c35f99);
    position: absolute;
    bottom: 15px;
    right: 8px;
  }
}

.popup {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  @include text-gold;
  text-align: center;
  white-space: nowrap;
}

.rewardText {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  @include text-gold;
  opacity: 0.5;

  &Turns {
    font-size: 11px;
    @include text-gold;
    opacity: 0.5;
    position: absolute;
    bottom: 12px;
    left: 5px;
  }

  &Coins {
    font-size: 10px;
    @include text-gold;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    right: 5px;
  }
}

.locked {
  position: absolute;
  top: -8px;
  right: -4px;
}

.active {
  opacity: 1;
}
