@mixin vp-1919 {
  @media (max-width: (1919px)) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: (1439px)) {
    @content;
  }
}

@mixin vp-1368 {
  @media (max-width: (1368px)) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: (1279px)) {
    @content;
  }
}

@mixin vp-1024 {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: (767px)) {
    @content;
  }
}

@mixin vp-435 {
  @media (max-width: (435px)) {
    @content;
  }
}

@mixin text-orange {
  background: linear-gradient(240.15deg, #f9dca0 -13.18%, #fe7762 111.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-yellow {
  background: linear-gradient(227.69deg, #feefd0 21.86%, #fcd587 100.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 8px 0px rgba(249, 220, 160, 0.7);
}

@mixin text-purple {
  background: linear-gradient(220.15deg, #ece2ff 0%, #b58cfd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-gold {
  background: linear-gradient(227.69deg, #feefd0 21.86%, #fcd587 100.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-blue {
  background: linear-gradient(137.58deg, #b3ffff 0.37%, #26dbdc 95.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin glow($blur, $color) {
  filter: drop-shadow(0px 0px $blur $color);
}

@mixin fadeIn {
  animation: 0.3s fadeIn ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin fadeOut {
  animation: 0.3s fadeOut ease-in-out;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
