@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 64px;
  width: 100vw;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  z-index: 100;
}

.info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.help {
  background-color: #1d1f24b2;
  backdrop-filter: blur(10px);
  border-radius: 50px;
  padding: 6px;
  width: 100%;
  height: 100%;

  &Wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    padding: 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        210deg,
        #2d2d2d 13.94%,
        #716e7a 50.81%,
        #2d2d2d 96.83%
      )
      border-box;
  }
}

.leaderboard {
  @include glow(8px, #f9dca0b2);

  &Container {
    border-radius: 50px;
    padding: 6px;
    backdrop-filter: blur(10px);
    background-color: #1d1f24b2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &Wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    padding: 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        210deg,
        #2d2d2d 13.94%,
        #716e7a 50.81%,
        #2d2d2d 96.83%
      )
      border-box;
  }
}

.coins {
  background: linear-gradient(
    219.11deg,
    rgba(90, 90, 90, 0.4) 13.94%,
    rgba(158, 154, 171, 0.7) 50.81%,
    rgba(90, 90, 90, 0.4) 96.83%
  );
  border-radius: 10px;
  position: relative;
  padding: 2px;
  width: 100px;

  &Content {
    padding: 8px 10px;
    width: 100%;
    height: 100%;
    background-color: #1d1f24b2;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 9px;
    backdrop-filter: blur(10px);
  }
}

.steps {
  padding: 2px;
  background: linear-gradient(
    190.11deg,
    rgba(90, 90, 90, 0.4) 13.94%,
    rgba(158, 154, 171, 0.7) 50.81%,
    rgba(90, 90, 90, 0.4) 96.83%
  );
  border-radius: 10px;
  position: relative;

  &Content {
    padding: 8px 26px 8px 10px;
    width: 100%;
    height: 100%;
    background-color: #1d1f24b2;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 9px;
    backdrop-filter: blur(10px);
  }
}

.popup {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  @include text-gold;
  text-align: center;
  white-space: nowrap;
}

.coin {
  width: 20px;
  height: 20px;
  @include glow(6px, #f5c35f99);
}

.dice {
  width: 20px;
  height: 20px;
  @include glow(8px, #feada1b2);
}

.text {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #f9dca0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add {
  width: 36px;
  height: 36px;
  position: absolute;
  right: -18px;
  top: calc(50% - 18px);
  cursor: pointer;
}
