@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  background-color: #0e0e0e30;
  backdrop-filter: blur(10px);
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
  padding: 2px;
  background: linear-gradient(
    219.11deg,
    rgba(90, 90, 90, 0.4) 13.94%,
    rgba(158, 154, 171, 0.7) 50.81%,
    rgba(90, 90, 90, 0.4) 96.83%
  );
  border-radius: 16px;
}

.content {
  background-color: #0e0e0ee6;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  @include text-orange;
}

.desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: $white;
}

.buttons {
  display: flex;
  gap: 8px;
  width: 100%;
}

button.tour {
  padding: 14px;
}

.tour {
  &Text {
    @include text-orange;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
  }

  &Wrapper {
    border-radius: 16px;
  }
}

button.support {
  padding: 14px;
}

.support {
  &Text {
    color: #702800;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
  }

  &Wrapper {
    border-radius: 16px;
    height: 60px;
  }
}
