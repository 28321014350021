@import 'src/styles/variables';
@import 'src/styles/mixins';

.countDiv {
  display: flex;
  align-items: center;
  gap: 4px;
}

.wrapper {
  width: 100%;
  border: 2px solid transparent;
  background: linear-gradient(
    220deg,
    rgba(90, 90, 90, 0.4) 13.94%,
    rgba(158, 154, 171, 0.7) 50.81%,
    rgba(90, 90, 90, 0.4) 96.83%
  );
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border-radius: 12px;
  padding: 2px;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  border-radius: 9px;
  gap: 8px;
  position: relative;
  background-color: #0e0e0e;
  z-index: 0;
}

.textStyle {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: $white;
}

.countStyle {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  @include text-orange;
}

.glow {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 12px;
  z-index: -1;
}

.coin {
  filter: drop-shadow(0px 0px 6px #f5c35f99);
}
