@import 'src/styles/variables';
@import 'src/styles/mixins';

.listTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  @include text-orange;
}

.place {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 600;
  color: $white;
  width: 28px;
}

.coins {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}
