@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  box-shadow: 0px 0px 20px 0px #f9dca033 inset;
  padding: 2px;
  background: linear-gradient(
    219.11deg,
    rgba(90, 90, 90, 0.4) 13.94%,
    rgba(158, 154, 171, 0.7) 50.81%,
    rgba(90, 90, 90, 0.4) 96.83%
  );
  border-radius: 12px;
  width: 100%;
}

.container {
  padding: 12px;
  background-color: #0e0e0ee5;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #f9dca033 inset;
}

.top {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Left {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.icon {
  background: #fe776233;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  @include glow(6px, #f5c35f99);
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: $white;
}

.reward {
  &Icon {
    width: 16px;
    height: 16px;
    filter: drop-shadow(0px 0px 8px #f9dca099);
  }

  &Text {
    color: #ffe2a6;
    filter: drop-shadow(0px 0px 8px #f9dca099);
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    margin-top: 2px;
  }
}

button.reward {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
}

div.rewardWrapper {
  border-radius: 50px;
  padding: 2px;
  width: fit-content;
}

button.go {
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 11px;
  text-align: center;
}

.go {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 9px;
  padding: 10px 16px;

  &Text {
    color: #702800;
    line-height: 20px;
  }

  &Img {
    width: 16px;
    height: 16px;
  }
}

.checkText {
  line-height: 20px;
  @include text-orange;
}

.claimText {
  line-height: 20px;
  color: #421877;
}

div.goWrapper {
  width: fit-content;
  border-radius: 10px;
  height: 32px;
  padding: 1.5px;
}
