@import 'src/styles/variables';
@import 'src/styles/mixins';

.modal {
  background-color: #0e0e0e;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 13px 16px 32px 16px;
  position: relative;
  z-index: 0;
  @include fadeIn;
  overflow: hidden;
  min-height: 100vh;
}

.back {
  position: absolute;
  top: 12px;
  left: 20px;
  z-index: 1;
}

.dices {
  width: 320px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 53px;
}

.titleHighlight {
  @include text-orange;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(240.15deg, #f9dca0 -13.18%, #fe7762 111.68%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 20px;
}

.firstDescription {
  @include text-purple;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.secondDescription {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 44px;
}

.footerContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  z-index: 1;
  width: 100%;
}

.textLined {
  text-decoration: line-through;
  font-weight: 400;
  margin-right: 10px;
  color: #421877;
}

.star {
  margin-right: 6px;
}

.paymentText {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: $white;
  opacity: 0.5;
  z-index: 1;
}

.diceTop {
  position: absolute;
  width: 200px;
  height: 226px;
  top: 0;
  right: -50px;
  transform: rotate(30deg);
  filter: blur(8px);
}

.diceMiddle {
  position: absolute;
  width: 300px;
  height: 320px;
  left: -150px;
  transform: rotate(-30deg);
  top: calc(50% - 80px);
  filter: blur(12px);
  opacity: 0.5;
}

.diceBottom {
  position: absolute;
  width: 410px;
  height: 456px;
  bottom: -170px;
  right: -200px;
  transform: rotate(40deg);
  filter: blur(20px);
}

.saleContainer {
  background: linear-gradient(244.92deg, #f9dca0 -2.55%, #fe7762 116.71%);
  border-radius: 30px;
  padding: 7px 12px;
}

.saleWrapper {
  padding: 1.5px;
  background: linear-gradient(
    190deg,
    rgba(138, 131, 118, 0) 14.97%,
    #ffecca 49.19%,
    rgba(138, 131, 118, 0) 85.92%
  );
  position: absolute;
  right: 5px;
  top: -23px;
  border-radius: 30px;
  z-index: 1;
}

.saleText {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #702800;
  text-align: center;
}
