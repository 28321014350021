@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  background: linear-gradient(
    223.64deg,
    transparent 14.97%,
    #ffecca 49.19%,
    transparent 85.92%
  );
  border-radius: 10px;
  padding: 2px;
  box-shadow: 0px 0px 10px 0px #f9dca066 inset;
  position: absolute;
  top: -64px;
  left: 16px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  border-radius: 8px;
  background-color: #0e0e0e;
  box-shadow: 0px 0px 8px 0px #f9dca066 inset;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.time {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  background: linear-gradient(227.69deg, #feefd0 21.86%, #fcd587 100.82%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 8px rgba(249, 220, 160, 0.7);
}

.text {
  font-weight: 600;
  font-size: 12px;
  line-height: 13.2px;
  background: linear-gradient(227.69deg, #feefd0 21.86%, #fcd587 100.82%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 8px rgba(249, 220, 160, 0.7);
}

.closeContainer {
  margin-left: 4px;
}
