@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include fadeIn;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 100px;
}

.chest {
  width: 70%;
  height: auto;
  aspect-ratio: 1 / 0.95;
  max-width: 380px;
  @include glow(30px, #f9dca04d);
}

.stars {
  position: absolute;
  top: 100px;
  width: 100%;
  max-width: 520px;
}

.glow {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  top: 0px;
}
