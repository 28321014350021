@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 72px;
  @include fadeIn;
}
