@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  width: 64px;
  position: relative;
  cursor: pointer;
}

.img {
  width: 24px;
  height: 24px;
}

.purple {
  @include glow(12px, #a26ffdb2);
}

.active {
  @include glow(12px, #feada180);
}

.glowSmall {
  top: -10px;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.label {
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  color: #7f7b8a;

  &Active {
    @include text-orange;
  }
}

img.ornament {
  width: 60%;
  @include glow(8px, #feada199);
}
