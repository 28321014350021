.shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  z-index: 1;
  background: linear-gradient(
    360deg,
    #0e0e0e 0%,
    rgba(14, 14, 14, 0.8) 48.35%,
    rgba(14, 14, 14, 0) 100%
  );
}
