@import 'src/styles/variables';
@import 'src/styles/mixins';

.modal {
  background-color: #0e0e0e;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 13px 16px 36px 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top, bottom;
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

.stars {
  position: absolute;
}

.crown {
  width: 112px;
  height: 112px;
  filter: drop-shadow(0px 0px 76px #a26ffd);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: $white;
  text-align: center;
  margin-bottom: 30px;
}

.titleGradient {
  background: linear-gradient(237.05deg, #f9dca0 3.49%, #fe7762 94.74%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.untillContainer {
  margin-bottom: 30px;
}

.description {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: $white;
  text-align: center;
  margin-bottom: 90px;
}

.turnText {
  background: linear-gradient(240.15deg, #f9dca0 -13.18%, #fe7762 111.68%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.buttonTitle {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: rgba(66, 24, 119, 1);
}
