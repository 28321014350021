@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  background-color: #ffffff1a;
}
