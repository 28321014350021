@import 'src/styles/variables';
@import 'src/styles/mixins';

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  max-height: 100%;
  @include fadeIn;
  z-index: -1;
  transition: all 0.3s;
}
