.line {
  width: 100%;
  border: 1px solid transparent;
  border-image-source: linear-gradient(
    90deg,
    rgba(90, 90, 90, 0) 1.93%,
    #a0a0a0 49.5%,
    rgba(90, 90, 90, 0) 99.21%
  );
  border-image-slice: 1;
}
