@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  padding: 80px 0 16px 0;
  height: 100%;
  @include fadeIn;
  z-index: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  gap: 36px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: $white;
  text-align: center;
}

.disclaimer {
  text-align: center;
  font-size: 14px;
  color: #625d6d;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.orangeTitle {
  @include text-orange;
}

.description {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: $white;
  text-align: center;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
}
