@import 'src/styles/variables';
@import 'src/styles/mixins';

.navbar {
  position: fixed;
  width: 100vw;
  height: 96px;
  bottom: 0;
  left: 0;
  padding: 16px 24px 24px 24px;
  background: #0e0e0e80;
  backdrop-filter: blur(20px);
  z-index: 100;
}

.divider {
  position: absolute;
  top: -1px;
  left: 0;
}

.items {
  display: flex;
  justify-content: space-between;
}
