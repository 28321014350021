@import 'src/styles/variables';
@import 'src/styles/mixins';

.friendsItemUser {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 2px;
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;

  &Div {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
}

.userNameStyle {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #9e9aac;
}

.countAndButtonDiv {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.countDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.countStyle {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  @include text-orange;
}

.imgIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  width: 20px;
  height: 20px;
}

.numberDiv {
  min-width: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15.4px;
  color: $white;
  margin-right: 6px;
}

button.button {
  padding: 10px 12px;
  border-radius: 10px;
}

div.buttonWrapper {
  border-radius: 10px;
  padding: 1px;
}

.displayNone {
  display: none;
}

.chackImageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  width: 24px;
  height: 24px;
}

.coin {
  @include glow(6px, #f5c35f99);
}
