@import 'src/styles/variables';
@import 'src/styles/mixins';

.friendsScreen {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #0e0e0e;
  position: relative;
  z-index: 0;
}

.friendsItem {
  display: flex;
  gap: 12px;
  width: 100%;
  margin-bottom: 20px;
}

.divider {
  margin-bottom: 20px;
}

.friendsData {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.friendsTitleCountDiv {
  display: flex;
  gap: 3px;
  margin-bottom: 10px;
}

.friendsTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  @include text-orange;
}

.line {
  width: 100%;
  height: 1px;
  background-color: gray;
  margin-top: 10px;
  margin-bottom: 20px;
}

.buttonAndCopyDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  z-index: 1;
  position: sticky;
  bottom: 110px;
  width: 100%;
}

.friendsItemUserList {
  padding-bottom: 140px;
}

.glow {
  position: absolute;
  width: 100%;
  z-index: -1;
  top: -60px;
}

.share {
  height: 52px;
  border-radius: 14px;

  &Wrapper {
    height: 56px;
    width: 100%;
  }
}

.copy {
  border-radius: 14px;
  height: 52px;

  &Container {
    height: 56px;
  }

  &Wrapper {
    width: 90px;
    min-width: 90px;
  }

  &Icon {
    @include glow(8px, #f9dca0b2);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s;
  }
}

.wrapper {
  padding: 72px 16px 0;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stars {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 72px;
  width: 80%;
}

.chest {
  width: 200px;
  height: 190px;
  @include glow(20px, #f9dca0b2);
}

.popover {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  @include text-gold;
  text-align: center;
}

.title {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 16px;
  color: $white;
  text-shadow: 0px 2px 1px 0px #1e1e1e;
}
