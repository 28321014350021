@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1.5px;
  border-radius: 16px;
  text-align: center;
  width: 100%;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.button {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: none;
  padding: 18px;
  font-size: 18px;
  font-weight: 600;
  font-family: $font;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  &Orange {
    background: linear-gradient(240.15deg, #f9dca0 -13.18%, #fe7762 111.68%);
    color: #702800;
  }

  &Purple {
    background: linear-gradient(220.15deg, #ece2ff 0%, #b58cfd 100%);
    color: #421877;
  }

  &Dark {
    background: #0e0e0ee6;
    color: #fff;
  }

  &Blue {
    background: linear-gradient(102.18deg, #26dbdc 16.47%, #b3ffff 84.47%);
    color: #1d1f24;
  }

  &Yellow {
    background: linear-gradient(227.69deg, #feefd0 21.86%, #fcd587 100.82%);
    color: #1d1f24;
  }
}
