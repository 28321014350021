@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  align-items: center;
  position: relative;
  @include fadeIn;
}

.top {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 600px;
  animation: slideRight 80s infinite linear;
}

.bottom {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 600px;
  animation: slideLeft 80s infinite linear;
}

.img {
  width: 200px;
  height: 230px;
}

@keyframes slideLeft {
  0% {
    transform: translateX(-20%);
  }

  25% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(20%);
  }

  75% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-20%);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(20%);
  }

  25% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-20%);
  }

  75% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(20%);
  }
}
