@import 'src/styles/variables';
@import 'src/styles/mixins';

.title {
  @include text-orange;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 16px;
  padding: 0 8px;
}

div.popoverContent {
  padding: 0;
  box-shadow: 0px 0px 16px 0px #f9dca066 inset;
  background-color: #0e0e0e;
  border-radius: 16px;
  padding: 50px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.description {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $white;
}

.limitedText {
  @include text-yellow;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-shadow: 0px 0px 8px #f9dca0b2;
  @include glow(8px, #f9dca0b2);
}

.limited {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.time {
  @include text-yellow;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-shadow: 0px 0px 8px #f9dca0b2;
  @include glow(8px, #f9dca0b2);
}

.buttonContainer {
  display: flex;
  gap: 8px;
}

.decline {
  @include text-orange;
}

.button {
  font-size: 16px;
}
