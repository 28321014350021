@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  height: 100%;
  position: relative;
  @include fadeIn;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 16px 36px;

  &Text {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    color: $white;
  }
}

.new {
  @include text-orange;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.button {
  padding: 18px 10px;
}

.continue {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: #702800;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.img {
  height: 235px;
  width: 235px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  @include text-orange;
  filter: drop-shadow(0px 2px 1px #1e1e1e);
  margin-bottom: 12px;
}

.desc {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  filter: drop-shadow(0px 2px 1px #1e1e1e);
  margin-bottom: 30px;

  span {
    @include text-orange;
  }
}

.reward {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  position: relative;
  margin-bottom: 20px;

  &Title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    @include text-gold;
    @include glow(8px, #f9dca080);
  }

  &Coins {
    display: flex;
    align-items: center;
    gap: 10px;

    &Img {
      @include glow(19px, #f9dca099);
      width: 35px;
      height: 35px;
    }

    &Text {
      @include glow(19px, #f9dca099);
      @include text-gold;
      font-size: 33px;
      font-weight: 600;
      line-height: 33px;
    }

    &Stars {
      position: absolute;
      top: -10px;
    }
  }
}

.hint {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  margin-bottom: 20px;
  @include text-gold;
}

div.shareButtonWrapper {
  width: fit-content;
  border-radius: 10px;
  padding: 1px;
}

button.shareButton {
  padding: 10px 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.shareIcon {
  width: 20px;
  height: 20px;
}

.shareText {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #1d1f24;
}
