@import 'src/styles/variables';
@import 'src/styles/mixins';

.slider {
  display: flex !important;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}

.wrapper {
  padding: 2px;
  background: linear-gradient(
    219.11deg,
    rgba(90, 90, 90, 0.4) 13.94%,
    rgba(158, 154, 171, 0.7) 50.81%,
    rgba(90, 90, 90, 0.4) 96.83%
  );
  border-radius: 12px;
  width: 100%;
  min-height: 320px;
  overflow: hidden;
}

.info {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.top {
  position: relative;
  height: 246px;
}

.title {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  &Clues {
    @include text-orange;
  }

  &Locations {
    @include text-purple;
  }

  &Characters {
    @include text-blue;
  }
}

.clues {
  border-radius: 10px;
  background-color: #0e0e0eb2;
}

.locations {
  border-radius: 10px;
  background-color: #0e0e0eb2;
}

.characters {
  border-radius: 10px;
  background-color: #0e0e0eb2;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  list-style: disc;
  padding-left: 24px;
  opacity: 0.5;
}
