@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.error {
  background-color: #fe776233;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &Text {
    color: #fe7762;
  }
}

div.buttonWrapper {
  margin-top: 8px;
  border-radius: 8px;
}

.buttonText {
  height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 18px;
  font-weight: 500;
  font-size: 16px;
  color: #702800;
}

button.button {
  border-radius: 8px;
  padding: 8px;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.imgWrapper {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  border-radius: 16px;
  max-width: 480px;
  margin: 0 auto;
  position: relative;
}

.offer {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #0e0e0e;
  padding: 8px 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px #f9dca066 inset;

  &Wrapper {
    background: linear-gradient(
      13deg,
      rgba(138, 131, 118, 0) 14.97%,
      #ffecca 49.19%,
      rgba(138, 131, 118, 0) 85.92%
    );
    padding: 2px;
    position: absolute;
    border-radius: 10px;
    bottom: 8px;
    right: 8px;
    z-index: 1;
    box-shadow: 0px 0px 8px 0px #f9dca066 inset;
  }

  &Time {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    @include text-gold;
    @include glow(10px, #f9dca0b2);
  }

  &Text {
    font-size: 12px;
    font-weight: 600;
    line-height: 13px;
    @include text-gold;
    @include glow(10px, #f9dca0b2);
  }

  &Content {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.closeOffer {
  @include glow(8px, #f9dca0b2);
}
