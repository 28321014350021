@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  padding: 72px 16px 16px 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  @include fadeIn;
}

.top {
  position: relative;
  z-index: 1;

  &Img {
    width: 180px;
    height: 200px;
  }

  &Arrow {
    position: absolute;
    right: -90px;
    top: 60px;
  }
}

.bottom {
  position: relative;
  z-index: 1;

  &Img {
    width: 180px;
    height: 200px;
    margin-top: 160px;
  }

  &Arrow {
    position: absolute;
    transform: rotate(180deg);
    left: -90px;
    bottom: 60px;
  }
}
