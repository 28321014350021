.layout {
  position: relative;
  height: 100%;
  width: 100%;
}

.content {
  height: 100%;
  width: 100%;
}
