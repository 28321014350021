@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  overflow: auto;
}

button.glow {
  box-shadow: 0px 0px 20px 0px #f9dca033 inset;
  padding: 14px 20px;
  border-radius: 8px;
}

div.glowWrapper {
  box-shadow: 0px 0px 20px 0px #f9dca033 inset;
  border-radius: 10px;
}
