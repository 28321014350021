@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  padding: 1.5px;
  background: linear-gradient(
    219.11deg,
    rgba(90, 90, 90, 0.4) 13.94%,
    rgba(158, 154, 171, 0.7) 50.81%,
    rgba(90, 90, 90, 0.4) 96.83%
  );
  border-radius: 12px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 358px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }
}

.reward {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #0e0e0e80;
  padding: 4px 8px;
  border-radius: 40px;

  &Wrapper {
    padding: 2px;
    border-radius: 40px;
    background: linear-gradient(
      219.11deg,
      rgba(90, 90, 90, 0.4) 13.94%,
      rgba(158, 154, 171, 0.7) 50.81%,
      rgba(90, 90, 90, 0.4) 96.83%
    );
  }

  &Text {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    @include text-gold;
    @include glow(8px, #f9dca099);
  }

  &Icon {
    @include glow(6px, #f5c35f99);
    width: 16px;
    height: 16px;
  }
}

.progress {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #0e0e0e80;
  border-radius: 40px;
  padding: 4px 8px;

  &Icon {
    @include glow(8px, #f9dca0b2);
  }

  &Text {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: $white;
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}

.content {
  padding: 12px;
  border-radius: 12px;
  height: 280px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.panel {
  padding: 12px;
  background: #0e0e0e66;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 0 0 12px 12px;
  height: 100px;
  backdrop-filter: blur(10px);
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  z-index: 10;
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 36px;
}

.author {
  color: #9e9aac;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: -1;
  max-height: 296px;
  object-position: top;
}

button.button {
  width: fit-content;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px 12px;
  border-radius: 9px;
}

.button span {
  color: #702800;
}

div.buttonWrapper {
  width: fit-content;
  border-radius: 10px;
  padding: 1.5px;
  box-shadow: 0px 0px 18px 0px #f9dca04d;
}

.tags {
  display: flex;
  gap: 6px;
}
