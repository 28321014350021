@import 'src/styles/variables';
@import 'src/styles/mixins';

.username {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #9e9aac;
}

.img {
  width: 32px;
  height: 32px;
}
