@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  height: 415px;
}

.slider {
  display: flex !important;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.bullet {
  transition: width 0.5s;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #f9dca0;
  opacity: 0.5;
  display: block;
}

.bulletActive {
  transition: width 0.5s;
  width: 16px;
  opacity: 1;
  box-shadow: 0px 0px 8px 0px #f9dca0b2;
}
