@import 'src/styles/variables';
@import 'src/styles/mixins';

.modal {
  background-color: #0e0e0e;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 13px 16px 36px 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top, bottom;
}

.backContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.closeButton {
  z-index: 1;
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.star {
  &Wrapper {
    display: inline;
    height: 20px;
    width: 15px;
    display: flex;
    align-items: center;
  }
}

.stars {
  position: absolute;
}

.crown {
  width: 112px;
  height: 112px;
  filter: drop-shadow(0px 0px 76px #a26ffd);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  margin-bottom: 16px;
}

.untillContainer {
  margin-bottom: 16px;
}

.description {
  font-weight: 600;
  font-size: 18px;
  line-height: 23.4px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  margin-bottom: 30px;
}

.turnText {
  background: linear-gradient(240.15deg, #f9dca0 -13.18%, #fe7762 111.68%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.line {
  margin-bottom: 30px;
}

.limitedTimeContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 20px;
  border-radius: 12px;
  background-color: #0e0e0e;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 0px #f9dca066 inset;

  &Wrapper {
    background: linear-gradient(
      190.64deg,
      transparent 14.97%,
      #ffecca 49.19%,
      transparent 85.92%
    );
    border-radius: 16px;
    padding: 2px;
    box-shadow: 0px 0px 10px 0px #f9dca066 inset;
    margin-bottom: 30px;
  }
}

.limitedTimeText {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding-top: 2px;
  background: linear-gradient(227.69deg, #feefd0 21.86%, #fcd587 100.82%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 8px 0px rgba(249, 220, 160, 0.7);
  @include glow(8px, #f9dca0b2);
}

.limitedTime {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  background: linear-gradient(227.69deg, #feefd0 21.86%, #fcd587 100.82%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 8px 0px rgba(249, 220, 160, 0.7);
  @include glow(8px, #f9dca0b2);
}

.paymentDescription {
  font-weight: 600;
  font-size: 18px;
  line-height: 23.4px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  background: linear-gradient(227.92deg, #f9dca0 3.75%, #fe7762 84.27%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 44px;
}

.footerContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.buttonTitle {
  color: #421877;
}
