.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 80px 16px 120px;
  position: relative;
  z-index: 0;
}

.reset {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: right;
  color: #9e9aac;
}

img.gradient {
  top: -100px;
}
