@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}

.heading {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition:
    max-height 0.3s ease,
    padding 0.3s ease;
  overflow: hidden;
}

.collapsed {
  max-height: 120px;
  padding-top: 0;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  @include text-orange;
}

.tasks {
  display: flex;
  gap: 5px;
  align-items: center;
}

.arrow {
  width: 16px;
  height: 16px;
  transition: transform 0.3s;
}

.reverse {
  transform: rotate(180deg);
}
