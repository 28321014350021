@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
}

.popover {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.popoverTitle {
  @include text-orange;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.step {
  display: flex;
  gap: 10px;
}

.text {
  color: $white;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.number {
  color: #fe7762;
  border-radius: 10px;
  padding: 8px 12px;
  background-color: #fe776233;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  height: fit-content;
}

.buttons {
  display: flex;
  gap: 8px;
  width: 100%;
}

button.button {
  width: 100%;
}

.support {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  @include text-orange;
  white-space: nowrap;
}
