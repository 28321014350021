@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  min-height: 100%;
  @include fadeIn;
  position: relative;
}

.content {
  width: 100%;
  height: 100%;
}

.info {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;
}

.step {
  font-weight: 600;
  font-size: 16px;
  line-height: 22.4px;
  color: rgba(255, 255, 255, 0.64);
  margin-bottom: 16px;
}

.title {
  @include text-orange;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: $white;
  margin-bottom: 16px;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: $white;
  margin-bottom: 20px;
}

.buttonText {
  font-size: 18px;
}
