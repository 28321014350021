@import 'src/styles/variables';
@import 'src/styles/mixins';

.icon {
  object-fit: cover;
  border-radius: 12px;
}

.titleStyle {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: $white;
}
