@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include fadeIn;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  position: relative;
}

.cups {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 10;
  max-width: 480px;
}

.stars {
  position: absolute;
  top: 50px;
  height: 80%;
}
