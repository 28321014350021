@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 13px 16px 36px 16px;
  background-color: #0e0e0e;
  position: relative;
  z-index: 0;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.star {
  &Wrapper {
    display: inline;
    height: 20px;
    width: 15px;
    display: flex;
    align-items: center;
  }
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.closeButton {
  z-index: 1;
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  display: flex;
  align-items: center;
}

.stars {
  position: absolute;
}

.crown {
  width: 112px;
  height: 112px;
  filter: drop-shadow(0px 0px 76px #a26ffd);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line {
  margin-bottom: 30px;
}

.limitedTimeText {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  padding-top: 2px;
  @include text-gold;
  @include glow(8px, #f9dca0b2);
}

.limitedTime {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  @include text-gold;
  @include glow(8px, #f9dca0b2);
}

.limitedTimeWrapper {
  padding: 2px;
  background: linear-gradient(
    223.64deg,
    rgba(138, 131, 118, 0) 14.97%,
    #ffecca 49.19%,
    rgba(138, 131, 118, 0) 85.92%
  );
  margin-bottom: 30px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(249, 220, 160, 0.4) inset;
}

.limitedTimeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  background-color: rgba(14, 14, 14, 1);
  padding: 20px 20px 16px 20px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px 0px rgba(249, 220, 160, 0.4) inset;
}

.paymentDescription {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: $white;
  text-align: center;
  background: linear-gradient(227.92deg, #f9dca0 3.75%, #fe7762 84.27%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 44px;
}

.footerContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 18px 0;
  border-radius: 16px;
  background: linear-gradient(220.15deg, #ece2ff 0%, #b58cfd 100%);
}

.buttonTitle {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: rgba(66, 24, 119, 1);
}
