@import 'src/styles/variables';
@import 'src/styles/mixins';

.line {
  border: 1px solid;
  border-image-source: linear-gradient(
    90deg,
    #5a5a5a00 10%,
    #a0a0a0 49.5%,
    #5a5a5a00 90%
  );
  border-image-slice: 1;
  width: 100%;
}

.rewardsAmount {
  color: $white;
}

.notInLeagueText {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #9e9aac;
  height: 32px;
  display: flex;
  align-items: center;
}

.trophy {
  width: 24px;
  height: 24px;
  @include glow(8px, #f9dca0b2);
}
