@import 'src/styles/variables';
@import 'src/styles/mixins';

.amount {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: #f9dca0;
}

.icon {
  @include glow(6px, #f5c35f99);
}
