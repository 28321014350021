@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  background-color: #0e0e0e99;
  @include fadeIn;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  padding: 2px;
  background: linear-gradient(227.69deg, #feefd0 21.86%, #fcd587 100.82%);
  border-radius: 16px;
  box-shadow: 0px 0px 40px 0px #fddfa299;
}

.content {
  background-color: #0e0e0e;
  padding: 32px 30px;
  border-radius: 14px;
}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
}
