@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.last {
  height: 140px;
}

.answer {
  background-color: #0e0e0eb2;
  padding: 10px 12px;
  border-radius: 12px;
  color: $white;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  scroll-margin-top: 80px;

  ul,
  ol {
    padding-left: 16px;
  }
}

.question {
  color: #fe7762;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &Wrapper {
    position: relative;
    max-width: 260px;
    background-color: #1d1f24;
    width: fit-content;
    align-self: flex-end;
    padding: 10px 12px;
    border-radius: 12px;
    margin-right: 8px;
  }
}

.tail {
  position: absolute;
  bottom: -1px;
  right: -4px;
}
