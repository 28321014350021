@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.quests {
  @include text-orange;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
}

.dice {
  height: 100px;
  width: 100px;
  @include glow(10px, #feada1b2);
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  @include text-orange;
}

.desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: $white;
}

.timer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.wait {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  @include text-gold;
  @include glow(8px, #f9dca0b2);
}

.time {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  @include text-gold;
  @include glow(8px, #f9dca0b2);
}

.buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sub {
  color: #421877;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;

  &Button {
    display: flex;
    flex-direction: column;
    padding: 13px 12px;
  }

  &Icon {
    @include glow(8px, #feada1b2);
  }

  &Bottom {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
