@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  height: 100%;
  position: relative;
  z-index: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}

.background {
  position: absolute;
  top: -20px;
  left: -20px;
  object-fit: cover;
  width: 110%;
  height: 110%;
  z-index: -1;
  filter: blur(10px);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  transition: all 0.3s;
}
